import usePageBuilder from "../hooks/usePageBuilder";
import useSnipcartEvents from "../hooks/useSnipcartEvents";

const Education = (data) => {
  const { props } = usePageBuilder({
    content: data.pageContext.data.content,
    allImages: data.pageContext.data.images,
  });

  useSnipcartEvents();

  return props.children;
};

export default Education;
